import styled from "styled-components";


// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  font-weight:500;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const Header = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2.5rem 0.625rem 2.5rem;
  width: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 99999;
`;

export const MainMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
`;

export const MenuItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.25rem;
`;

export const Wrapper = styled.div`
max-width: 1200px;
    width: 100%;
    margin: 1.25rem auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const para = styled.p`
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Rubik","Verdana",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    line-height: 1.75rem;
    white-space: pre-line;
    width: 100%;
    text-align: justify;
    margin: 1.25rem auto 5rem auto;
    color: var(--primary-text);
`;

export const NavbarStyled = styled.div`
font-size: 24px;
position: fixed;
width: 100%;
.header {
  background: black;
  padding: 40px;
  display: flex;
  justify-content: space-around;
  margin: 0;
  color: white;
  * {
    cursor: pointer;
  }
  .active {
    border-bottom: 1px solid white;
  }
}
.scrolling-buttons {
  display: flex;
  flex-direction: column;
}
`;



