import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { load} from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Navbar from "./Components/Navbar";
import Section from "./Components/Section";
import Accordion from 'react-bootstrap/Accordion';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "bootstrap";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--stonky-border);
  padding: 10px;
  font-weight: bold;
  color: #ffffff;
  width: 300px;
  height: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary-text);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 6px 0px -2px rgb(173, 173, 173, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--stonky-border);
  background-color: var(--primary);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StonkyImg = styled.img`
  width: 400px;
  padding-top: 100px;
  @media (min-width: 900px) {
    width: 400px;
  }
  @media (max-width: 900px) {
    width: 300px;
  }
  @media (max-width: 475px) {
    width: 200px;
  }
  transition: width 0.5s;
`;
export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  /*
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account,mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! Once they are revealed visit Opensea.io to view.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    dispatch(connect());
  }, []);
 */
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };
 

  useEffect(() => {
    getConfig();
  }, []);
  return (
    
    <s.Screen>
      <Navbar />
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
        id="About"
      > 
      <s.Wrapper>  
      <StonkyImg alt={"stonky"} src={"/config/images/stonky_trans.png"}/>
        {/* <StyledButton>
          <a class="buttonLink" href="https://mint.stonkycandles.com">Mint</a>
        </StyledButton> 
        */}
        <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
            >
              About Stonky Candles 
        </s.TextTitle>
          <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }} >
          <s.SpacerSmall /><s.SpacerSmall />
          <iframe width="560" height="560" src="https://www.youtube.com/embed/BXJfVilzXL0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <s.para className="stonky-about">Stonky Candles is a collection of 10,000 generative art candles created from over 150 elements inspired by traders all across the globe. 
             Each candle is unique, with its own features and traits.<br/><br/>Stonky loves stocks, crypto and NFTs.<br/><br/> 
              We are dedicated to creating a strong community of owners and traders. The Stonky Candles team will create unique content for traders of all kinds with special access given to owners. Join 
              us and spread the word about the exciting opportunities that exist for taking control of new technologies and our financial futures.
            </s.para>
            
        </s.Container>
          </s.Wrapper>
        </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg_fly.png" : null}
        id="Roadmap"
      >
         <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
            >
              Roadmap
        </s.TextTitle>
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#000000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="Oct 2021"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Launch Twitter</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Nov 2021"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Discord and website launch</h3>
    
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Oct 2022"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Complete artwork</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="TBD"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Start whitelist for presales</h3>
          <h4 className="vertical-timeline-element-subtitle">Giveaways</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="TBD"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Presale </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="TBD"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Public Sale</h3>
          <h4 className="vertical-timeline-element-subtitle">Giveaways for NFTs at 2500, 5000, 7500, 10000</h4>
          <p>
          When the project sells out Stonky will donate $50,000 to charity of the community’s choice.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="TBD"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Create content to share with the community</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="TBD"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Stonky events,  giveaways, airdrops, merch</h3>
          <h4 className="vertical-timeline-element-subtitle">Raffles for NFTs at 2500, 5000, 7500, 10000</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="TBD"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          
        >
          <h3 className="vertical-timeline-element-title">Stonky Evolutions</h3>
        
        </VerticalTimelineElement>
      </VerticalTimeline>
      <span className="subject">*Roadmap is subject to change</span>
      </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg_pool.png" : null}
        id="Team"
      >
         <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
            >
              Meet the Team
        </s.TextTitle>
        <s.Wrapper style={{maxWidth: 1000}}>
        <div className="row section-content">
                <div className="col-lg-12">
                    <div className="row justify-content-end team-members ">
                        <div className="col-lg-4 col-md-12 col-sm-12 mb-2 lead">
                            <div className="team-member-box mb-8 mb-lg-0 p-3">
                                <div className="avatar">
                                </div>
                                <div className="title">Candleman</div>
                                <div className="value">
                                    Filmmaker, trader, coder
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mb-2 artist">
                            <div className="team-member-box mb-8 mb-lg-0 p-3">
                                <div className="avatar">

                                </div>
                                <div className="title">Brianna</div>

                                <div className="value">
                                    Passionate graphic designer and artist
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mb-2 writer">
                            <div className="team-member-box mb-8 mb-lg-0 p-3">
                                <div className="avatar">
                                </div>
                                <div className="title">Paddywack</div>
                                <div className="value">
                                    Writer, Filmmaker
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge />
        </s.Wrapper>
      </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg_jet.png" : null}
        id="FAQ"
      >
         <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
            >
              FAQ
        </s.TextTitle>
        <ResponsiveWrapper
          style={{maxWidth:1200}}>
          <div className="col-lg-12">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>When is the launch?</Accordion.Header>
                <Accordion.Body>
                  TBD
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Which blockchain?</Accordion.Header>
                <Accordion.Body>
                  Ethereum
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What is the mint price and how many can I mint?</Accordion.Header>
                <Accordion.Body>
                .035 ETH during the presale to mint plus gas. The price will go up for public mint.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Will there be merch?</Accordion.Header>
                <Accordion.Body>
                  Yes. We anticipate when the collection is at 50% sold we will start creating merch.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Is there a presale?</Accordion.Header>
                <Accordion.Body>
                  Yes. It will be limited to 72 hours with the regular sale starting 1 week after. There will be a presale open to people that make it onto the whitelist. We want to provide an opportuntiy to those that are invested in and want to help us build this community. More details to come.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>How do I install MetaMask?</Accordion.Header>
                <Accordion.Body>
                  <ol className="list-group list-group-numbered">
                      <li className="list-item">
                          Navigate to <a href="https://metamask.io/download.html" target="_blank">metamask.io/download.html</a>.
                      </li>
                      <li className="list-item">
                          Click on Install MetaMask for your browser
                          <small>and you will be redirected to Extensions or Addon Management for&nbsp;your browser.</small>
                      </li>
                      <li className="list-item">
                          Click on the <strong>Add</strong> button.
                      </li>
                      <li className="list-item">
                          Once installation is complete this page will be displayed. Click on the <strong>Get Started</strong> button.
                      </li>
                      <li className="list-item">Follow the prompt on Metamask.</li>
                      <li className="list-item">Please Refresh the Page.</li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>How do I get ETH into MetaMask?</Accordion.Header>
                <Accordion.Body>
                You can easily buy Ethereum with a debit card or Apple Pay directly within MetaMask by clicking “Add funds”. You can request funds from a friend by sending them a payment request showing your QR code in person or by sharing your public address.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>How do I transfer my existing ETH and tokens to MetaMask?</Accordion.Header>
                <Accordion.Body>
                You can send ETH and tokens from another wallet to a new MetaMask account. Simply copy your new MetaMask public address and  go to your existing wallet or exchange to send funds to your new wallet address.
                <p>You can find your MetaMask address under account name (in the format 0x12r45...6HJ9).</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </ResponsiveWrapper>
      </s.Container>  
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg_sunset.png" : null}
        id="FAQ">
          <ul className="footer-menu">
            <li className="nav-item"><a href="https://twitter.com/stonkycandles" target="_blank" className="btn social-media-icon">
                <i className="fab fa-twitter fs-1"></i>
            </a></li>
            <li className="nav-item"><a href="https://discord.gg/KVhEDUrf9Y" target="_blank" className="btn social-media-icon">
                <i className="fab fa-discord fs-1"></i>
            </a></li>

            <li className="nav-item"><a href="https://www.instagram.com/stonkycandles/" target="_blank" className="btn social-media-icon">
                <i className="fab fa-instagram fs-1"></i>
              </a>
            </li>
          </ul>
      </s.Container>
    </s.Screen>
  );
}

export default App;
