import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";


export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <img
            src={"/config/images/logo.png"}
            className="nav-logo"
            alt="Logo"
            onClick={this.scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="About"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="Roadmap"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Roadmap
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="Team"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Team
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="FAQ"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                FAQ
              </Link>
            </li>
            <li className="nav-item">
              <a href="https://twitter.com/stonkycandles" target="_blank" className="btn social-media-icon">
                  <i className="fab fa-twitter fs-4"></i>
              </a>
              <a href="https://discord.gg/KVhEDUrf9Y" target="_blank" className="btn social-media-icon">
                  <i className="fab fa-discord fs-4"></i>
              </a>

              <a href="https://www.instagram.com/stonkycandles/" target="_blank" className="btn social-media-icon">
                  <i className="fab fa-instagram fs-4"></i>
              </a>
            </li>
            <li id="wallet-account"></li>
          </ul>
        </div>
      </nav>
    );
  }
}